// ProcessedNewsPage.js
import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Spin,
  Alert,
  Typography,
  Button,
  Modal,
  Tabs,
  Row,
  Col,
  Divider,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Layout from "../Layout";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const ProcessedNewsPage = () => {
  const accessToken = localStorage.getItem("accessToken");
  // Base server URL
  const server_url = "https://newsbotdev.newrlabs.com";

  // State for processed news data, loading, and error handling
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Modal state for showing full post details
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  // (Optional) If you plan to filter by date in the future:
  // const [selectedDate, setSelectedDate] = useState(null);

  // Fetch processed news from the backend
  const fetchProcessedNews = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${server_url}/list-processed-news`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to fetch processed news.");
      }
      const data = await response.json();
      // data is expected to have: { processed_data: [ ... ] }
      setNewsData(data.processed_data || []);
    } catch (err) {
      console.error("Error fetching processed news:", err);
      setError(err.message || "Failed to fetch processed news.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProcessedNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handleRefresh = () => {
    fetchProcessedNews();
  };

  // Opens the modal with the selected post
  const handleReadMore = (post) => {
    setSelectedPost(post);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedPost(null);
  };

  // Return the "English" version if available, otherwise the first available language
  const getDefaultVersion = (item) => {
    if (!item.multilingual) return null;
    if (item.multilingual.English) {
      return item.multilingual.English;
    }
    const languages = Object.keys(item.multilingual);
    if (languages.length > 0) {
      return item.multilingual[languages[0]];
    }
    return null;
  };

  // (Optional) For future date filtering:
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   // If your backend supports filtering, pass date to fetchProcessedNews
  //   // fetchProcessedNews(date.format("YYYY-MM-DD"));
  // };

  return (
    <Layout pageTitle="Processed News">
      <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
        {/* Header with gradient background */}
        <div
          style={{
            background: "linear-gradient(135deg, #4b6cb7, #182848)",
            padding: "40px",
            borderRadius: "8px",
            marginBottom: "30px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <Title style={{ color: "#fff", marginBottom: "10px" }}>
            Processed News Dashboard
          </Title>
          <Paragraph style={{ color: "#e0e0e0", fontSize: "16px" }}>
            Explore all processed news items across multiple languages. Stay updated with the latest curated news.
          </Paragraph>
          <Row justify="center" gutter={16} style={{ marginTop: "20px" }}>
            {/* <Col>
              <DatePicker
                onChange={handleDateChange}
                style={{ borderRadius: "4px" }}
                placeholder="Select Date (Optional)"
              />
            </Col> */}
            <Col>
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={handleRefresh}
                style={{ borderRadius: "4px" }}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </div>

        {/* Error or Loading States */}
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            style={{ marginBottom: "20px" }}
          />
        )}
        {loading ? (
          <Spin
            tip="Loading processed news..."
            size="large"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <List
            grid={{
              gutter: 24,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={newsData}
            locale={{ emptyText: "No processed news available." }}
            renderItem={(item, index) => {
              const defaultVersion = getDefaultVersion(item);
              return (
                <List.Item key={index}>
                  <Card
                    hoverable
                    style={{
                      borderRadius: "8px",
                      overflow: "hidden",
                      minHeight: "240px",
                    }}
                    title={
                      <Text strong style={{ fontSize: "16px" }}>
                        {defaultVersion ? defaultVersion.headline : "Untitled News"}
                      </Text>
                    }
                    extra={
                      <Text type="secondary" style={{ fontSize: "12px" }}>
                        {defaultVersion?.category || ""}
                      </Text>
                    }
                    actions={[
                      <Button type="link" onClick={() => handleReadMore(item)}>
                        Read More
                      </Button>,
                    ]}
                  >
                    <div
                      style={{ height: "80px", overflow: "hidden", marginBottom: "10px" }}
                    >
                      {defaultVersion ? (
                        <div dangerouslySetInnerHTML={{ __html: defaultVersion.story }} />
                      ) : (
                        "No description available."
                      )}
                    </div>
                    {defaultVersion?.image_link && (
                      <img
                        src={server_url + defaultVersion.image_link}
                        alt={defaultVersion.headline}
                        style={{ width: "100%", borderRadius: "4px" }}
                      />
                    )}
                  </Card>
                </List.Item>
              );
            }}
          />
        )}

        {/* Modal for full post details with language tabs */}
        <Modal
          visible={modalVisible}
          title={
            selectedPost && getDefaultVersion(selectedPost)
              ? getDefaultVersion(selectedPost).headline
              : "News Detail"
          }
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          width={800}
        >
          {selectedPost && selectedPost.multilingual && (
            <Tabs defaultActiveKey="English">
              {Object.keys(selectedPost.multilingual).map((lang) => {
                const version = selectedPost.multilingual[lang];
                return (
                  <TabPane tab={lang} key={lang}>
                    <Title level={4}>{version.headline}</Title>
                    <Text strong>Category: </Text>
                    <Text>{version.category || "N/A"}</Text>
                    <Divider />
                    <div dangerouslySetInnerHTML={{ __html: version.story }} />
                    {version.image_link && (
                      <img
                        src={server_url + version.image_link}
                        alt={version.headline}
                        style={{ width: "100%", marginTop: "20px", borderRadius: "4px" }}
                      />
                    )}
                    <Divider />
                    <Text type="secondary">
                      SEO Tags: {version.seo_tags || "N/A"}
                    </Text>
                  </TabPane>
                );
              })}
            </Tabs>
          )}
        </Modal>
      </div>
    </Layout>
  );
};

export default ProcessedNewsPage;
