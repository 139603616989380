// DraftNewsPage.js
import React, { useEffect, useState } from "react";
import {
  List,
  Card,
  Spin,
  Alert,
  Typography,
  Button,
  Modal,
  Tabs,
  Form,
  Input,
  Select,
  Divider,
  Upload,
  message,
} from "antd";
import { ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import Layout from "../Layout"; // Your custom layout component
import moment from "moment";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

/**
 * LanguageEditorForm
 *
 * This component handles editing for a single language.
 * It calls Form.useForm() at the top level (thus complying with React hook rules)
 * and accepts initial values and callback functions for saving, image uploading,
 * image generation, and publishing.
 */
const LanguageEditorForm = ({
  language,
  initialValues,
  onSave,
  onUploadImage,
  onGenerateImage,
  onPublishLanguage,
  categories,
}) => {
  const [form] = Form.useForm();

  // Set initial values when they change.
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => onSave(form, language)}
    >
      <Form.Item
        name="headline"
        label="Headline"
        rules={[{ required: true, message: "Headline is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="story" label="Story">
        <TextArea rows={6} />
      </Form.Item>
      <Form.Item name="category" label="Category">
        <Select allowClear placeholder="Select a category">
          {categories.map((cat) => (
            <Option key={cat} value={cat}>
              {cat}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="seo_tags" label="SEO Tags">
        <Input />
      </Form.Item>
      <Form.Item name="image_link" label="Image Link">
        <Input placeholder="Direct link to image" />
      </Form.Item>
      <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
        {/* Manually upload image */}
        <Upload
          beforeUpload={(file) => {
            onUploadImage(file, language);
            return false; // Prevent auto upload
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
        {/* Generate image via DALL-E */}
        <Button onClick={() => onGenerateImage(language)}>
          Generate Image (DALL-E)
        </Button>
        {/* Publish single language */}
        <Button type="primary" onClick={() => onPublishLanguage(language)}>
          Publish {language}
        </Button>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </Form.Item>
    </Form>
  );
};

const DraftNewsPage = () => {
  const accessToken = localStorage.getItem("accessToken");
  const server_url = "https://newsbotdev.newrlabs.com"; // Base server URL

  // State for the list of draft news items.
  const [draftNewsData, setDraftNewsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for available categories (fetched from /list-categories)
  const [categories, setCategories] = useState([]);

  // State for controlling the edit modal.
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(null);

  // Fetch draft news and categories when component mounts.
  useEffect(() => {
    fetchDraftNews();
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // ---- FETCH DRAFT NEWS ----
  const fetchDraftNews = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${server_url}/list-news?status=draft`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to fetch draft news.");
      }
      const data = await response.json();
      setDraftNewsData(data || []);
    } catch (err) {
      console.error("Error fetching draft news:", err);
      setError(err.message || "Failed to fetch draft news.");
    } finally {
      setLoading(false);
    }
  };

  // ---- FETCH CATEGORIES ----
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${server_url}/list-categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data || []);
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  // ---- OPEN/CLOSE EDIT MODAL ----
  const handleEditDraft = (draft) => {
    setSelectedDraft(draft);
    setEditModalVisible(true);
  };

  const handleCloseModal = () => {
    setEditModalVisible(false);
    setSelectedDraft(null);
  };

  // ---- UTILITY: Get all languages from the draft's multilingual block ----
  const getLanguages = (draft) => {
    if (!draft.multilingual) return [];
    return Object.keys(draft.multilingual);
  };

  // ---- SAVE CHANGES for a single language ----
  const handleSaveLanguage = async (form, language) => {
    if (!selectedDraft) return;
    try {
      const values = await form.validateFields();
      const response = await fetch(
        `${server_url}/update-draft/${selectedDraft._id}/${language}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(values),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update draft.");
      }
      const data = await response.json();
      message.success(data.message || "Draft updated successfully.");
      // Refresh draft news list to reflect updates.
      fetchDraftNews();
    } catch (err) {
      message.error(err.message);
    }
  };

  // ---- UPLOAD IMAGE for a given language ----
  const handleUploadImage = async (file, language) => {
    if (!selectedDraft) return;
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(
        `${server_url}/upload-draft-image/${selectedDraft._id}/${language}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to upload image.");
      }
      const data = await response.json();
      message.success(data.message || "Image uploaded successfully.");
      fetchDraftNews();
    } catch (err) {
      console.error("Image upload error:", err);
      message.error(err.message || "Image upload failed.");
    }
  };

  // ---- GENERATE IMAGE via DALL-E for a given language ----
  const handleGenerateImage = async (language) => {
    if (!selectedDraft) return;
    try {
      const response = await fetch(
        `${server_url}/generate-draft-image/${selectedDraft._id}/${language}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to generate image.");
      }
      const data = await response.json();
      message.success(data.message || "DALL-E image generated.");
      fetchDraftNews();
    } catch (err) {
      console.error("Error generating image:", err);
      message.error(err.message || "Image generation failed.");
    }
  };

  // ---- PUBLISH a single language for the draft ----
  const handlePublishLanguage = async (language) => {
    if (!selectedDraft) return;
    try {
      const response = await fetch(
        `${server_url}/publish-draft/${selectedDraft._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ language }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || "Failed to publish language."
        );
      }
      const data = await response.json();
      message.success(
        data.message || "Language published successfully."
      );
      fetchDraftNews();
    } catch (err) {
      console.error("Error publishing language:", err);
      message.error(err.message || "Failed to publish language.");
    }
  };

  // ---- PUBLISH all languages for the draft ----
  const handlePublishAll = async (draft) => {
    try {
      const response = await fetch(
        `${server_url}/publish-draft/${draft._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ all_languages: true }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to publish draft.");
      }
      const data = await response.json();
      message.success(data.message || "Draft published successfully.");
      fetchDraftNews();
    } catch (err) {
      console.error("Error publishing draft:", err);
      message.error(err.message || "Failed to publish draft.");
    }
  };

  // ---- RENDER a Card for each draft item ----
  const renderDraftCard = (draft) => {
    const dateStr = draft.created_at
      ? moment(draft.created_at).format("YYYY-MM-DD HH:mm")
      : "N/A";
    const firstLang =
      draft.multilingual && Object.keys(draft.multilingual)[0];
    const firstHeadline =
      firstLang && draft.multilingual[firstLang].headline
        ? draft.multilingual[firstLang].headline
        : draft.original_headline || "Untitled Draft";

    return (
      <Card
        hoverable
        style={{
          borderRadius: "8px",
          overflow: "hidden",
          minHeight: "200px",
        }}
        title={
          <Text strong style={{ fontSize: "16px" }}>
            {firstHeadline}
          </Text>
        }
        extra={
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {dateStr}
          </Text>
        }
        actions={[
          <Button
            type="link"
            onClick={() => handleEditDraft(draft)}
            key="edit"
          >
            Edit
          </Button>,
          <Button
            type="link"
            onClick={() => handlePublishAll(draft)}
            key="publish"
            style={{ color: "green" }}
          >
            Publish All
          </Button>,
        ]}
      >
        <div
          style={{
            height: "60px",
            overflow: "hidden",
            marginBottom: "10px",
          }}
        >
          {draft.original_story
            ? draft.original_story.substring(0, 100) + "..."
            : "No preview available."}
        </div>
      </Card>
    );
  };

  // ---- Render the Edit Modal with a tab for each language in the draft ----
  const renderEditModal = () => {
    if (!selectedDraft) return null;
    const languages = getLanguages(selectedDraft);
    return (
      <Modal
        visible={editModalVisible}
        title="Edit Draft"
        onCancel={handleCloseModal}
        footer={null}
        width={900}
      >
        <Tabs>
          {languages.map((lang) => {
            const langContent = selectedDraft.multilingual[lang] || {};
            return (
              <TabPane tab={lang} key={lang}>
                <LanguageEditorForm
                  language={lang}
                  initialValues={{
                    headline: langContent.headline,
                    story: langContent.story,
                    category: langContent.category,
                    seo_tags: langContent.seo_tags,
                    image_link: langContent.image_link,
                  }}
                  onSave={handleSaveLanguage}
                  onUploadImage={handleUploadImage}
                  onGenerateImage={handleGenerateImage}
                  onPublishLanguage={handlePublishLanguage}
                  categories={categories}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </Modal>
    );
  };

  return (
    <Layout pageTitle="Draft News">
      <div style={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
        {/* Header Section */}
        <div
          style={{
            background: "linear-gradient(135deg, #f093fb, #f5576c)",
            padding: "40px",
            borderRadius: "8px",
            marginBottom: "30px",
            color: "#fff",
            textAlign: "center",
          }}
        >
          <Title style={{ color: "#fff", marginBottom: "10px" }}>
            Draft News
          </Title>
          <Paragraph style={{ color: "#e0e0e0", fontSize: "16px" }}>
            Manage your draft news items. Edit headlines, categories, images, or
            publish them to WordPress.
          </Paragraph>
          <Button
            type="primary"
            icon={<ReloadOutlined />}
            onClick={fetchDraftNews}
            style={{ borderRadius: "4px" }}
          >
            Refresh
          </Button>
        </div>

        {/* Error and Loading States */}
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            style={{ marginBottom: "20px" }}
          />
        )}
        {loading ? (
          <Spin
            tip="Loading draft news..."
            size="large"
            style={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <List
            grid={{
              gutter: 24,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={draftNewsData}
            locale={{ emptyText: "No draft news available." }}
            renderItem={(item, index) => (
              <List.Item key={index}>{renderDraftCard(item)}</List.Item>
            )}
          />
        )}

        {/* Edit Modal */}
        {renderEditModal()}
      </div>
    </Layout>
  );
};

export default DraftNewsPage;
